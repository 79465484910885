import { getPage } from '../data-interface/sanity'

export default function IndexPage() {
  return null
}

export async function getStaticProps() {
  const data = await getPage('home')

  return {
    props: {
      data,
    },
    revalidate: parseInt(process.env.REVALIDATE),
  }
}
